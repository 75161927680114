.ios-messages {
	//max-width: 450px;
	//margin: 50px auto;
	position: relative;
	background: none;
	padding: 25px 20px 0;
	div {
		//max-width: 55px;
		//word-wrap: break-word;
		//margin-bottom: 12px;
		//line-height: 24px;
		&:after {
    		//content: "";
    		//display: table;
    		//clear: both;
        }
	}
}
.clear {
    clear: both;
}
.from-me {
	position: relative;
	padding: 10px 20px;
	color: white;
	background: #0B93F6;
	border-radius: 25px;
	float: right;
	margin-bottom: 20px;
	margin-right: 10px;
	&:before {
		content:"";
		position:absolute;
		z-index:-1;
		bottom:-2px;
		right:-7px;
		height:20px;
		border-right:20px solid #0B93F6;
		border-bottom-left-radius: 16px 14px;
		-webkit-transform:translate(0, -2px);
		z-index: 20;
	}
	&:after {
		content:"";
		position:absolute;
		z-index:1;
		bottom:-2px;
		right:-56px;
		width:26px;
		height:20px;
		background:white;
		border-bottom-left-radius: 10px;
		-webkit-transform:translate(-30px, -2px);
		z-index: 20;
	}
}
.from-them {
	position:relative;
	padding:10px 20px;
	background:#E5E5EA;
	border-radius:25px;
	color: black;
	float: left;
	margin-bottom: 20px;
	margin-left: 10px;
	&:before {
		content:"";
		position:absolute;
		z-index:2;
		bottom:-2px;
		left:-7px;
		height:20px;
		border-left:20px solid #E5E5EA;
		border-bottom-right-radius: 16px 14px;
		-webkit-transform:translate(0, -2px);
	}
	&:after {
		content:"";
		position:absolute;
		z-index:3;
		bottom:-2px;
		left:4px;
		width:26px;
		height:20px;
		background:white;
		border-bottom-right-radius: 10px;
		-webkit-transform:translate(-30px, -2px);
	}
}
.from-me,
.from-them{
    p{
        margin-bottom: 0;
    }
    .time_date{
        font-size: 80%;
    }
}