/*
    page headers styles
*/
.page-header{
    background-color: $header-background;
    background-position: center center;
    background-size: cover;
    height: 0;
    padding: 0;
    max-height: 999px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    &.page-header-small{
        min-height: 65vh !important;
        max-height: 700px;
    }
    &.page-header-xs{
        min-height: 40vh !important;
    }
    &.page-header-41{
        padding-top: 25%;
        @media(max-width: 767px){
            min-height: 30vh;
            padding-top: 0;
        }
        @media(max-width: 575px){
            min-height: 30vh;
            padding-top: 0;
        }
    }
    &.page-header-31{
        padding-top: 33.33%;
        @media(max-width: 767px){
            min-height: 40vh;
            padding-top: 0;
        }
        @media(max-width: 575px){
            min-height: 50vh;
            padding-top: 0;
        }
    }
    &.page-header-21{
        padding-top: 50%;
        @media(max-width: 767px){
            min-height: 40vh;
            padding-top: 0;
        }
        @media(max-width: 575px){
            min-height: 50vh;
            padding-top: 0;
        }
    }
    &.page-header-169{
        padding-top: 56.25%;
        @media(max-width: 767px){
            min-height: 40vh;
            padding-top: 0;
        }
        @media(max-width: 575px){
            min-height: 50vh;
            padding-top: 0;
        }
    }
    &.page-header-43{
        padding-top: 75%;
        @media(max-width: 767px){
            min-height: 40vh;
            padding-top: 0;
        }
        @media(max-width: 575px){
            min-height: 50vh;
            padding-top: 0;
        }
    }
    &.page-header-vh100{
        height: 100vh;
    }
    &.page-header-vh75{
        height: 75vh;
    }
    &.page-header-vh50{
        height: 50vh;
    }
    &.page-header-vh25{
        height: 25vh;
    }
    &.page-header-vh10{
        height: 10vh;
    }

    @media(orientation: landscape) and (max-width: 767px){
        &.page-header-31,
        &.page-header-21,
        &.page-header-169,
        &.page-header-43{
            min-height: 100vh;
            padding-top: 0;
        }
    }
    .page-header-image{
        position: absolute;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .page-header-content{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        color: white;
        //background: rgba(255, 0, 0, 0.6);
        .row,
        .container,
        .col-12{
            height: 100%;
        }
        .swerkCaption{
            position: absolute;
            left: 15px;
            right: 15px;
            h2{
                font-size: 2.5rem;
                font-weight: 200;
                text-transform: uppercase;
                //font-size: 1.9rem;
                @media(max-width: 767px){
                    font-size: 1.625rem;
                }
                @media(max-width: 575px){
                    font-size: 1.425rem;
                }
            }
            h3{
                font-size: 24px;
                font-weight: 200;
                text-transform: uppercase;
                @media(max-width: 767px){
                    font-size: 20px;
                }
                @media(max-width: 575px){
                    font-size: 18px;
                }
            }
            p{
                margin-bottom: 0;
            }
            ul,ol{
                margin-bottom: 0;
                padding: 0;
                li{
                    list-style: none;
                    position: relative;
                    padding-left: 24px;
                }
                li:before{
                    position: absolute;
                    left: 0;
                    font-family: 'Font Awesome 5 Pro';
                    content: '\f00c';
                    color: white;
                }
            }
            .btn-caption{
                text-transform: uppercase;
                margin-top: 10px;
            }
        }
        .text-left{
            .swerkCaption{
                @media(max-width: 767px){
                    left: 15px;
                }
            }
        }
        .text-right{
            .swerkCaption{
                @media(max-width: 767px){
                    right: 15px;
                }
            }
        }
        .text-top{
            .swerkCaption{
                top: 126px;
            }
        }
        .text-middle{
             .swerkCaption{
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .text-bottom{
            .swerkCaption{
                bottom: 40px;
            }
        }
    }

    .container{
        color: $white-color;
        position: relative;
        z-index: 3;
    }
    .description{
        color: $white-color;
    }

    .filter{
        &::after{
            background-color: rgba(0, 0, 0, 0.4);
            content: "";
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }
        &.filter-primary::after{
            background-color: rgba($filter-primary, 0.4);
        }
        &.filter-info::after{
            background-color: rgba($filter-info, 0.4);
        }
        &.filter-success::after{
            background-color: rgba($filter-success, 0.4);
        }
        &.filter-warning::after{
            background-color: rgba($filter-warning, 0.4);
        }
        &.filter-danger::after{
            background-color: rgba($filter-danger, 0.4);
        }
    }
}
.header-video{
    &.state-play{
        .filter::after{
            opacity: 0;
        }
        .video-text{
            opacity: 0;
        }

        .btn{
            opacity: 0;
        }

        &:hover{
            .btn{
                opacity: 1;
            }
        }
    }
}

// For IE Browser

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .page-header{
        .content-center{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%);
        }
        .presentation-title{
            background: none !important;
        }
    }
}


/* carousel */
.carousel-control{
    //background-color: $danger-color;
    background-color: rgba(0,0,0,.2);
    border-radius: 50%;
    opacity: 1;
    text-shadow: none;

    &:hover,
    &:focus{
        opacity: 1;
        //background-color: $danger-states-color;
        background-color: rgba(0,0,0,.2);
    }
}

.carousel-control.left{
    height: 50px;
    top: 50%;
    width: 50px;
    left: 25px;
    opacity: 0;
}
.carousel-control.right{
    height: 50px;
    right: 25px;
    top: 50%;
    width: 50px;
    opacity: 0;
}
.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .fa, .carousel-control .fa {
    display: inline-block;
    z-index: 5;
}
.carousel-control .fal{
    font-size: 26px;
    margin: 2px;
}
.carousel-control.left, .carousel-control.right {
    background-image: none;
}

 .page-carousel{
    //border-radius: $border-radius-extreme !important;
    border: none !important;
    width: 100%;
}

.carousel-inner > .carousel-item > img,
.carousel-inner > .carousel-item > a > img{
    //border-radius: $border-radius-extreme;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);

}

.carousel-indicators > li{
    background-color: rgba(204, 204, 204, 0.49);
    border: 0 none;
}
.carousel-indicators .active{
    background-color: $danger-color;
}
.page-carousel:hover{
    .carousel-control.right,
    .carousel-control.left{
        opacity: 1;
        z-index: 1;
    }
}
.card{
    .carousel{
        .carousel-indicators{
            bottom: 0;
        }
        a{
            position: absolute;
            color: $white-color !important;
        &:hover{
            color: $white-color !important;
        }
        .fa-angle-left:before{
            padding-right: 3px;
        }
        .fa-angle-right:before{
            padding-left: 3px;
        }
        }
    }
}





