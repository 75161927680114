// Font Awesome 5
@import "fontawesome/fontawesome.scss";
@import "fontawesome/brands.scss";
@import "fontawesome/light.scss";
@import "fontawesome/regular.scss";
@import "fontawesome/solid.scss";

// Variables
@import 'app/variables';

// Bootstrap
@import '../libs/bootstrap-4.1.3-dist/scss/bootstrap';
@import '../libs/lightbox-master/dist/ekko-lightbox';
@import '../libs/bootstrap-select-1.13.7/sass/bootstrap-select';
@import '../libs/bootstrap-datepicker/dist/css/bootstrap-datepicker';

@import 'app/headers';
@import 'app/footers';
@import 'app/navbars';
@import 'app/alerts';
@import 'app/cards';
@import 'app/video';
@import 'app/_accordions';
@import 'app/cookies';
@import 'app/socials';
@import 'app/messages';
@import 'libs/checkbox';


/*
    swerk webapp base styles
*/
html {
    position: relative;
}
html,
body {
    overflow-x: hidden;
    background: #f8f7f7;
    min-height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;
    font-weight: 300;
}
body {
    &.hasFixedNav{
        padding-top: 96px;
        .page-carousel{
            //margin-top: -96px;
        }
    }
    .font-weight-bold,
    strong,b{
        font-weight: 500 !important;
    }
    .description{
        font-size: 110%;
    }
    .text{
        a:not(.btn){
            text-decoration: underline;
        }
    }
}
h1,h2,h3,h4,h5,h6{
    font-weight: 300;
}
h1,h2{
    text-transform: uppercase;
}
h1{
    font-size: 1.9rem;
    @media(max-width: 767px){
        font-size: 1.625rem;
    }
    @media(max-width: 575px){
        font-size: 1.425rem;
    }
}
h2{
    font-size: 1.425rem;
    @media(max-width: 767px){
        font-size: 1.325rem;
    }
    @media(max-width: 575px){
        font-size: 1.125rem;
    }
}
.breadcrumb{
    margin-bottom: 1.5rem;
    background: #f8f9fa;
    background: none;
}
.form-control.form-checkboxes{
    height: auto;
}
.bg-white{
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 400'%3E%3Cg %3E%3Cpolygon
    fill='%23f5f5f5' points='1200 150 0 400 0 400 1200 400 1200 300'/%3E%3Cpolygon
    fill='%23f0f0f0' points='1200 200 0 400 0 400 1200 400 1200 300'/%3E%3Cpolygon
    fill='%23DBDADA' points='1200 250 0 400 0 400 1200 400 1200 300'/%3E%3C/g%3E%3C/svg%3E");
    background-attachment: scroll;
    background-position: center bottom;
    background-size: cover;
}
.opleiding_intro{
    background: rgba(0, 0, 0, 0.03);
    margin-bottom: 15px;
    border-radius: 8px;
    padding: 15px;
    p{
        margin-bottom: 0;
    }
}
.opleiding_sub{
    padding: 15px;
    //border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #eee;
    margin-bottom: 15px;
    h1{
        font-size: 1.4rem;
    }
}