.main-footer{
    background: rgba(0,0,0,.1);
    background: #DBDADA;
    padding-top: 50px;
    margin-top: -1px;
    h2{
        font-size: 19px;
        font-weight: 400;
        text-transform: uppercase;
    }
    address{
        margin-bottom: 0;
    }
    .copyright{
        margin-top: 10px;
        font-size: 90%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .nav-link {
        //display: block;
        //padding: 0.2rem 1rem;
        padding: 0;
        display: inline-block;
    }
    .microText{
        line-height: 1.3;
    }
}