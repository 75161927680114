.card{
    border: 0;
    box-shadow: 0 0 5px rgba(0,0,0,.05);
    margin-bottom: 30px !important;
    border-radius: 5px;
}
.card-img-top{
    background-color: $header-background;
    background-position: center center;
    background-size: cover;
    height: 0;
    padding-top: 56.25%;
}
.card-image-full{
    border-radius: 5px;
    background-color: $header-background;
    background-position: center center;
    background-size: cover;
}
.card{
    margin-bottom: 15px;
}
.gallery-card{
    .card-image-link{
        display: block;
        height: 0;
        padding-top: 56.25%;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        span{
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: white;
            padding: 5px 8px;
            z-index: 1;
        }
        img.card-image-full.img-fluid.lazy{
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            height: auto;
            width: auto;
            -webkit-transition: all .5s ease;
            transition:         all .5s ease;
            z-index: 0;
        }
        &:hover{
            img.card-image-full.img-fluid.lazy{
                transform: scale(1.05);
            }
        }
    }
}