.accordion{
    .card{
        .card-header{
            h5{
                .btn.btn-link{
                    display: block;
                    width: 100%;
                    text-align: left;
                    &:after{
                        font-family: 'Font Awesome 5 Pro';
                        content: fa-content($fa-var-chevron-down);
                        float: right;
                    }
                    &.collapsed{
                        &:after{
                            font-family: 'Font Awesome 5 Pro';
                            content: fa-content($fa-var-chevron-up);
                        }
                    }
                }
            }
        }
    }
}