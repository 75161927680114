.js-cookie-consent{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1030;
    margin-bottom: 0;
    border-radius: 0;
    .cookie-consent__message{
        line-height: 38px;
    }
}