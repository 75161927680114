//color: #1c94e0; /* twitter blue dark */
//color: #91D2FA; /* twitter blue light */
//color: rgb(23, 191, 99); /* twitter green */
//color: rgb(224, 36, 94); /* twitter red */
//color: rgb(29, 161, 242); /* twitter blue dark */
//color: rgb(145, 210, 250); /* twitter blue light */
// Body
//$body-bg: #243447;
//$body-bg: #EBEEF1;

// Typography
//$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif: 'Montserrat', Arial, sans-serif;
//$font-size-base: 0.9rem;
//$line-height-base: 1.6;

// Colors
/*$blue: #91D2FA;
$indigo: #1c94e0;
$purple: #9561e2;
$pink: #f66D9b;
$red: rgb(224,36,94);
$orange: #f6993f;
$yellow: #ffed4a;
$green: rgb(23,191,99);
$teal: #4dc0b5;
$cyan: #6cb2eb;
*/

$header-background:         #B2AFAB !default;

$primary-color:              #023652 !default;
$primary-states-color:       darken($primary-color, 10%) !default;

$success-color:              #6bd098 !default;
$success-states-color:       darken($success-color, 10%) !default;

$info-color:                 #51bcda !default;
$info-states-color:          darken($info-color, 10%) !default;

$warning-color:              #fbc658 !default;
$warning-states-color:       darken($warning-color, 10%) !default;

$danger-color:               #f5593d !default;
$danger-states-color:        darken($danger-color, 8%) !default;



$filter-primary:         darken($primary-color, 10%);
$filter-info:        darken($info-color, 10%);
$filter-success:        darken($success-color, 10%);
$filter-warning:       darken($warning-color, 10%);
$filter-danger:          darken($danger-color, 10%);

$white-color: white;


$primary:       $primary-color !default;
//$secondary:     $gray-600 !default;
$success:       $success-color !default;
$info:          $info-color !default;
$warning:       $warning-color !default;
$danger:        $danger-color !default;
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;