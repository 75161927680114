.brand,
.navbar-brand{
    img{
        display: block;
        max-height: 70px;
    }
}
.navbar-brand{
    img{
        border-radius: 8px;
    }
}
.navbar.bg-primary{
    //background-color: rgba(0,0,0,0.35) !important;
    .navbar-nav{
        margin-top: 39px;
        .nav-item{
            .nav-link{
                opacity: .8;
                color: white;
                font-weight: 400;
                font-size: 1.1rem;
                &:hover{
                    opacity: 1;
                }
            }
            .nav-link{
                text-transform: lowercase;
                padding-left: 15px;
                padding-right: 15px;
                @media(max-width: 991px){
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
            &.active{
                .nav-link{
                    opacity: 1;
                }
            }
        }
        .nav-item-phone{
            .nav-link{
                background: white;
                color: $primary;
                border-radius: 3px;
                opacity: 1;
                padding: 3px 8px;
            }
        }
    }
}
.hasAvatar{
    position: relative;
    z-index: 0;
    padding-left: 50px !important;
    .maintenanceNotification{
        position: relative;
        z-index: 2;
        color: $danger;
    }
    div.avatar{
        position: absolute;
        border-radius: 45px;
        left: 0;
        top: -1px;
        width: 40px;
        height: 40px;
        @media(max-width: 991px){
            left: 4px;
            top: 4px;
            width: 32px;
            height: 32px;
        }
        border: 0 solid rgba(0,0,0,0);
        background-repeat: no-repeat;
        background-position: center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        z-index: -1;
    }
}
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
    color: black;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}
.navbar.bg-dark .navbar-nav{
    li > a{
        border-radius: 4px;
    }
    li.active > a{
        color: white;
        background: $primary;
    }
    .dropdown{
        li.active > a,
        li > a{
            border-radius: 0;
        }
        .multi-level{
            .dropdown-menu{
                //border-radius: 4px;
                a.dropdown-item{
                    border-radius: 0;
                }
                li.active{
                    a{
                        background: $primary;
                        color: white;
                        border-radius: 0px;
                    }
                }
                li{
                    a{
                        border-radius: 0px;
                        background: white;
                        color: #212529;
                        &:hover{
                            background: $primary;
                            color: white;
                        }
                    }
                }
            }
            .nav-item a{
                background: none;
                color: #212529;
            }
            .nav-item a:hover,
            .nav-item.active a{
                background: $primary;
                color: white;
            }
        }
    }
}
.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
    color: black;
}