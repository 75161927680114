/*
    swerk webapp alerts styles
*/
.swerk-alert-container{
    transition: all 1s ease;
    position: fixed;
    z-index: 1040;
    top: 0;
    right: 5px;
    width: 300px;
    overflow: visible;
    height: 0;
    .swerk-alert{
        transition: all .5s ease;
        position: relative;
        right: 0;
        left: 120%;
        padding: 8px 16px;
        line-height: 24px;
        vertical-align: middle;
        margin: 5px 0;
        width: 300px;
        max-width: 100%;
        i{
            margin: 0;
            padding: 0;
            display: inline-block;
            position: relative;
            top: 3px;
            font-size: 24px;
        }
        &.active{
            right: 0;
            left: 0;
        }
        .swerk-alert-close-layer{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: rgba(0,0,0,0);
        }
    }
}
/*

    SWERK NOTIFICATIONS

*/
.swerk-notifcatie{
    position: fixed;
    z-index: 999999999999;
    top: -100px;
    left: 0;
    right: 0;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    margin-bottom: 0;
}
.swerk-notifcatie .alert{
    min-height: 81px;
    /*max-height: 100px;*/
    border: 0;
    border-radius: 0;
    color: white;
    overflow: hidden;
    box-shadow: 0;
    box-shadow: inset 0 0 0 rgba(255,255,255,0);
}
.swerk-notifcatie .alert p{
    float: left;
    font-size: 14px;
    line-height: 52px;
    margin-top: 0;
}
.swerk-not-title{
    display: inline-block;
    margin-right: 6px;
    line-height: 52px;
    height: 22px;
    font-weight: bold;
    position: relative;
    padding-left: 25px;
}
.swerk-notifcatie .alert i{
    font-size: 20px;
    line-height: 52px;
    position: absolute;
    left: 0;
    top: -1px;
}
.swerk-notifcatie .alert-success{
    background: #27c24c;
}
.swerk-notifcatie .alert-info{
    background: #23b7e5;
}
.swerk-notifcatie .alert-danger{
    background: #f05050;
}
.swerk-notifcatie .alert-warning{
    background: #fad733;
}
.swerk-notifcatie .alert .close{
    color: white;
    opacity: 1;
    position: absolute;
    right: 15px;
    top: 15px;
}
.swerk-notifcatie .alert .close i{
    font-size: 18px;
}